import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

function CodeBlocks() {
  const curlCode = `
curl -X 'GET' \\
  'https://ok.surf/api/v1/news-feed' \\
  -H 'accept: application/json'


curl -X 'POST' \\
  'https://ok.surf/api/v1/news-section' \\
  -H 'accept: application/json' \\
  -H 'Content-Type: application/json' \\
  -d '{"sections": ["Business"]}'
  `;
  const jsCode = `
fetch('https://ok.surf/api/v1/cors/news-feed').then((res) => {
  if (!res.ok) {
    throw Error('could not fetch data for that resource');
  }
  return res.json();
}).then((data) => {
  console.log(data);
}).catch((err) => {
  console.log(err);
});
   `;
  const pythonCode = `
from json import load
from pprint import pprint
from urllib.request import Request, urlopen


req = Request('https://ok.surf/api/v1/news-feed')
req.add_header('accept', 'application/json')
req.add_header('Content-Type', 'application/json')
response = load(urlopen(req))
pprint(response)
  `;
  return (
    <Tabs
      defaultActiveKey="javascript"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="curl" title="CURL">
        <SyntaxHighlighter language="bash" style={docco}>
          {curlCode}
        </SyntaxHighlighter>
      </Tab>
      <Tab eventKey="javascript" title="Javascript">
        <SyntaxHighlighter language="javascript" style={docco}>
          {jsCode}
        </SyntaxHighlighter>
      </Tab>
      <Tab eventKey="python" title="Python">
        <SyntaxHighlighter language="python" style={docco}>
          {pythonCode}
        </SyntaxHighlighter>
      </Tab>
    </Tabs>
  );
}

export default CodeBlocks;
