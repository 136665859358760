import Container from 'react-bootstrap/Container';
import BootstrapNavbar from './BootstrapNavbar';
import Endpoints from './Endpoints';
import Feed from './Feed';
import HeroImg from './HeroImg';
import Services from './Services';
import TryIt from './TryIt';
import UpButton from './UpButton';

function Home() {
  return (
    <div id="home">
      <BootstrapNavbar isHome />
      <div>
        <HeroImg />
        <br />
        <Container>
          <Services />
        </Container>
        <Container>
          <Endpoints />
        </Container>
        <Container>
          <TryIt />
        </Container>
        <Container>
          <Feed />
        </Container>
        <UpButton />
      </div>
    </div>
  );
}

export default Home;
