import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopyright, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import React from 'react';
import TermsModal from './TermsModal';
import PrivacyModal from './PrivacyModal';

function Footer() {
  const footerTitle = ` ${new Date().getFullYear()} OkSurf  `;
  const linkStyle = { textDecoration: 'none' };
  const btnStyle = { textDecoration: 'none', paddingBottom: '14px' };
  const [modalShowTerms, setModalShowTerms] = React.useState(false);
  const [modalShowPrivacy, setModalShowPrivacy] = React.useState(false);

  return (
    <div id="footer" className="bg-dark text-center">
      <div className="justify-content-center align-items-center text-light pt-3 pb-3">

        <button
          className="btn btn-link text-light me-3 me-md-5 fs-5"
          style={btnStyle}
          onClick={() => setModalShowTerms(true)}
        >
          Terms
        </button>

        <button
          className="btn btn-link text-light me-3 me-md-5 fs-5"
          style={btnStyle}
          onClick={() => setModalShowPrivacy(true)}
        >
          Privacy
        </button>

        <span className="me-3 me-md-5">
          <FontAwesomeIcon icon={faCopyright} />
          {footerTitle}
        </span>

        <br className="d-md-none" />
        <a
          href="https://www.facebook.com/ok.surf.api"
          className="text-light me-3 me-md-5"
          style={linkStyle}
          target="_blank"
          rel="noreferrer"
          aria-label="Facebook"
        >
          <FontAwesomeIcon icon={faFacebookF} />
        </a>

        <a
          href="https://twitter.com/ok_surf_api"
          className="text-light me-3 me-md-5"
          style={linkStyle}
          target="_blank"
          rel="noreferrer"
          aria-label="X"
        >
          <FontAwesomeIcon icon={faXTwitter} />
        </a>

        <a href="mailto:admin@ok.surf" className="text-light" style={linkStyle} aria-label="Email">
          <FontAwesomeIcon icon={faEnvelope} />
        </a>

        <TermsModal
          show={modalShowTerms}
          onHide={() => setModalShowTerms(false)}
        />
        <PrivacyModal
          show={modalShowPrivacy}
          onHide={() => setModalShowPrivacy(false)}
        />
      </div>
    </div>
  );
}

export default Footer;
