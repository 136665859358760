import Image from 'react-bootstrap/Image';
import { Col, Row, Stack } from 'react-bootstrap';
import {
  faCheckCircle, faCoffee, faNewspaper, faGlobe, faFileCode, faDesktopAlt, faPlay,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lightGradient, ulStyle } from './utils';

function getIntro() {
  return (
    <div id="services" className="mt-4">
      <br />
      <h2>
        <FontAwesomeIcon icon={faPlay} style={{ color: '6610F2' }} />
        &thinsp;Services
      </h2>
      <p className="fs-5">
        The OkSurf News API provides a simple, free REST API for developers
        to retrieve the latest Google news in a familiar JSON format
      </p>
      <ul style={ulStyle()}>
        <li className="f-subheadline-l">
          <FontAwesomeIcon icon={faCheckCircle} className="text-primary" />
          &thinsp; This news service is absolutely free
        </li>
        <li className="f-subheadline-l">
          <FontAwesomeIcon icon={faCheckCircle} className="text-primary" />
          &thinsp; No account or signup is required
        </li>
        <li className="f-subheadline-l">
          <FontAwesomeIcon icon={faCheckCircle} className="text-primary" />
          &thinsp; Retrieve all Google News headlines
        </li>
      </ul>
      <br />
    </div>
  );
}

function getFeatures() {
  return (
    <div className="mt-5">
      <Stack gap={3}>
        <h3>
          Other Features
        </h3>
        <div>
          <FontAwesomeIcon icon={faNewspaper} size="2x" />
          &emsp;
          <span className="fs-5">News is available for each major Google News section</span>
          <p className="text-muted">
            US, World, Business, Technology, Entertainment, Sports, Science, & Health
          </p>
        </div>
        <div>
          <FontAwesomeIcon icon={faGlobe} size="2x" />
          &emsp;
          <span className="fs-5">Endpoints are available with or without CORS headers</span>
          <p className="text-muted">
            Developer friendly endpoints for backends and frontends
          </p>
        </div>
        <div>
          <FontAwesomeIcon icon={faDesktopAlt} size="2x" />
          &emsp;
          <span className="fs-5">Access the API with your favorite programming language</span>
          <p className="text-muted">
            Use your preferred language&apos;s HTTP client to make requests
          </p>
        </div>
        <div>
          <FontAwesomeIcon icon={faFileCode} size="2x" />
          &emsp;
          <span className="fs-5">Swagger documentation is available</span>
          <p className="text-muted">
            Test the API right in your browser and view example CURL commands
          </p>
        </div>
        <div>
          <FontAwesomeIcon icon={faCoffee} size="2x" />
          &emsp;
          <span className="fs-5">Works well with coffee</span>
          <p className="text-muted">
            Hot or iced, coffee can improve the overall API experience
          </p>
        </div>
      </Stack>
    </div>
  );
}

function getServicesImage() {
  return (
    <div className="pt-xs-1 pt-md-3">
      <Image src="/services.jpg" fluid="true" height="800" width="523" />
    </div>
  );
}

function Services() {
  return (
    <div className="mb-5 bg-image">
      <Row>
        <Col xs={12} lg={6}>
          {getIntro()}
        </Col>
        <Col xs={12} lg={6}>
          {getServicesImage()}
        </Col>
      </Row>
      <Row className="mt-4" style={lightGradient()}>
        <Col xs={1} md={3} />
        <Col xs={10} md={6}>
          {getFeatures()}
        </Col>
        <Col xs={1} md={3} />
      </Row>
    </div>
  );
}

export default Services;
