import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React from 'react';

function termsModalContent() {
  const sections = [
    [
      'Terms:',
      `By accessing the Service and Data at https://ok.surf, you
      are agreeing to be bound by these terms of service, all applicable
      laws and regulations, and agree that you are responsible for
      compliance with any applicable local laws. If you do not agree with
      any of these terms, you are prohibited from using or accessing this
      site. The materials contained in this website are protected by
      applicable copyright and trademark law.`,
    ],
    [
      'Use License:',
      `Permission is granted use the services made available on the
      OkSurf website for as long as your use case requires. This is
      the grant of a license, not a transfer of title, and under
      this license you may not: attempt to decompile or reverse
      engineer any software contained on the OkSurf website, remove
      any copyright or other proprietary notations from the materials,
      attempt to circumvent any restrictions or limits placed on your
      IP, use the service to reproduce or republish copyrighted
      material, or use the service in any manner that violates any
      laws directly or indirectly. This license shall automatically
      terminate if you violate any of these restrictions and may be
      terminated by OkSurf at any time, with or without cause, with
      or without notice, effective immediately. All provisions of
      this Agreement which by their nature should survive termination
      shall survive termination, including, without limitation,
      ownership provisions, warranty disclaimers, waiver and release,
      indemnity and limitations of liability.`,
    ],
    [
      'Data License:',
      `As part of the OkSurf Service, OkSurf offers a free news REST API.
      At no time is any of the OkSurf user-related data shared with third
      party organizations, nor is it used for collection and analysis
      purposes. Data accessible through OkSurf may contain third party
      content (such as text, images, videos). This content will remain
      the sole responsibility of those who make it available. Unless
      applicable by law or expressly permitted by the owner, you will not,
      and will not permit others acting on your behalf to use OkSurf.`,
    ],
    [
      'Copyright Infringement:',
      `If you believe that your own copyrighted work is accessible on the
      Service in violation of your copyright, you may provide our
      Designated Agent with a written communication as set forth in the
      Digital Millennium Copyright Act ("DMCA"), 17 U.S.C. § 512(c)(3)
      that contains the following written information: A description
      identifying in sufficient detail the copyrighted work or intellectual
      property that you claim has been infringed so it may be accurately
      located using the URL. Where multiple works are at issue please
      provide a list of URLs or sufficient information to locate the alleged
      infringing material. An electronic or physical signature of the person
      authorized to act on behalf of the owner of the copyright interest; A
      statement by you that you have a good-faith belief that the disputed
      use is unauthorized by the copyright owner, its agent, or the law; A
      statement by you made under penalty of perjury, that the above
      information in your notice is accurate and that you are the copyright
      owner or authorized to act on the copyright owner's behalf. Also
      include your name, mailing address, telephone number and email address.`,
    ],
    [
      'Disclaimer:',
      `The materials on the OkSurf website are provided on an "as is" basis.
      OkSurf makes no warranties, expressed or implied, and hereby disclaims
      and negates all other warranties including, without limitation, implied
      warranties or conditions of merchantability, fitness for a particular
      purpose, or non-infringement of intellectual property or other violation
      of rights. Furthermore, OkSurf does not warrant or make any representations
      concerning the accuracy, likely results, or reliability of the use of
      the materials on its website or otherwise relating to such materials or
      on any sites linked to this site. News articles shown on OkSurf do not
      necessarily reflect the views of our organization, and are gathered
      through well known sources deemed to be legitimate. OkSurf also
      recognizes that sources deemed legitimate are typically pushing a
      narrative that serves corporate interests and financial gain. OkSurf
      will not be liable for damages caused by news article content.
      Furthermore, OkSurf is in no way owned or operated by Google or Alphabet 
      Inc.`,
    ],
    [
      'Indemnification:',
      `You will defend, indemnify and hold OkSurf harmless against any third-
      party claims, liabilities or expenses incurred (including reasonable
      attorneys’ fees), as well as amounts finally awarded in a settlement
      or by a court arising from any claim or allegation by a third party
      arising out of (i) allegations that Customer's usage of the OkSurf
      service infringes or misappropriates any trademark, copyright or other
      intellectual property of any third party, (ii) Customer’s use of Data,
      (iii) OkSurf services that are modified by Customer if the alleged
      infringement relates to such modification; (iv) OkSurf services that
      are combined with any Customer product, process or materials where the
      alleged infringement relates to such combination; (v) Customer’s use
      of a version of the OkSurf services other than the version that was
      current at the time of such use; (vi) infringement or misappropriation
      of any proprietary right in which Customer has an interest; or (vii)
      your breach or alleged breach of any of your obligations or
      representations under this Agreement.`,
    ],
    [
      'Limitations:',
      `In no event shall OkSurf or its suppliers be liable for any damages
      (including, without limitation, damages for loss of data or profit,
      or due to business interruption) arising out of the use or inability
      to use the materials on the OkSurf website, even if OkSurf or a OkSurf
      authorized representative has been notified orally or in writing of
      the possibility of such damage. Because some jurisdictions do not allow
      limitations on implied warranties, or limitations of liability for
      consequential or incidental damages, these limitations may not apply
      to you. OkSurf will not be held liable for service outages or other
      circumstances that result in end-user application outages or issues.
      By using OkSurf you agree that you are doing so at your own risk.`,
    ],
    [
      'Links:',
      `OkSurf has not reviewed all the sites linked to its website and is
      not responsible for the contents of any such linked site. The inclusion
      of any link does not imply endorsement by OkSurf of the site. Use of any
      such linked website is at the user's own risk.`,
    ],
    [
      'Modifications:',
      `OkSurf may revise these terms of service for its website at any time
      without notice. By using this website you are agreeing to be bound by
      the then current version of these terms of service.`,
    ],
    [
      'Governing Law:',
      `These terms and conditions are governed by and construed in accordance
      with the laws of the United States and you irrevocably submit to the
      exclusive jurisdiction of the courts in your State or location.`,
    ],
  ];

  return sections.map(([title, text]) => (
    <>
      <strong>
        {title}
      </strong>
      <p className="mt-2 mb-3">
        {text}
      </p>
    </>
  ));
}

function TermsModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          OkSurf Terms of Service
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {termsModalContent()}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default TermsModal;
