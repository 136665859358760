import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function UpButton() {
  const buttonStyle = {
    position: 'fixed',
    width: '60px',
    height: '30px',
    bottom: '40px',
    right: '6px',
    textAlign: 'center',
  };

  return (
    <div id="upButton">
      <a href="/" style={buttonStyle} aria-label="Reload">
        <FontAwesomeIcon icon={faChevronUp} className="text-muted" style={{ marginTop: '24px' }} size="2x" />
      </a>

    </div>

  );
}

export default UpButton;
