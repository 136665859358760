import { Col, Row } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faPlay } from '@fortawesome/free-solid-svg-icons';
import { lightGradient, ulStyle } from './utils';

function getEndpointList() {
  const endpoints = [
    [
      '/news-section-names',
      'GET',
      'Fetch available Google News section names, such as World, Sports, and Science',
    ],
    [
      '/news-feed',
      'GET',
      'Retrieve all available Google News articles, for all available section names',
    ],
    [
      '/news-section',
      'POST',
      'Get all available news articles for the specified Google News section name(s)',
    ],
  ];
  const endpointList = endpoints.map(([endpoint, requestType, message]) => (
    <li>
      <code className="text-primary">{endpoint}</code>
      <h5 className="text-muted">{requestType}</h5>
      <p>{message}</p>
    </li>
  ));
  return (
    <ul style={ulStyle()}>{endpointList}</ul>
  );
}

function getEndpoints() {
  const tdStyle = { border: 'none' };
  return (
    <div className="mt-5 mb-lg-5">
      <br />
      <h2>
        <FontAwesomeIcon icon={faPlay} style={{ color: '6610F2' }} />
        &thinsp;
        Endpoints
      </h2>
      <p className="fs-5">
        Retrieve the latest news as valid JSON:
      </p>
      <div style={lightGradient()}>
        {getEndpointList()}
      </div>
      <div className="pe-4" style={lightGradient()}>
        <ul className="mt-1" style={ulStyle()}>
          <li className="f-subheadline-l">
            <FontAwesomeIcon icon={faInfoCircle} className="text-primary" />
            &thinsp; Each article in the response will contain the following keys:
            <br />
            <div className="mb-3 me-1 mb-4 ps-4">
              <Table size="sm" className="mt-1">
                <tbody>
                  <tr>
                    <td style={tdStyle} className="pt-0 pb-0"><b>title</b></td>
                    <td style={tdStyle} className="pt-0 pb-0">article title</td>
                  </tr>
                  <tr>
                    <td style={tdStyle} className="pt-0 pb-0"><b>link</b></td>
                    <td style={tdStyle} className="pt-0 pb-0">article URL</td>
                  </tr>
                  <tr>
                    <td style={tdStyle} className="pt-0 pb-0"><b>og</b></td>
                    <td style={tdStyle} className="pt-0 pb-0">og:image URL</td>
                  </tr>
                  <tr>
                    <td style={tdStyle} className="pt-0 pb-0"><b>source</b></td>
                    <td style={tdStyle} className="pt-0 pb-0">name of news source</td>
                  </tr>
                  <tr>
                    <td style={tdStyle} className="pt-0 pb-0"><b>source_icon</b></td>
                    <td style={tdStyle} className="pt-0 pb-0">source logo/icon URL</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}

function getEndpointsImage() {
  return (
    <div>
      <Image src="/features.svg" fluid="true" height="600" width="600" />
    </div>
  );
}

function Endpoints() {
  return (
    <div id="endpoints" className="mb-5 bg-image">
      <Row>
        <Col xs={12} xl={6}>
          {getEndpoints()}
        </Col>
        <Col xs={12} xl={6} className="mt-xl-5 text-center">
          <br />
          <div>
            {getEndpointsImage()}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Endpoints;
