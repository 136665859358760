import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import Swagger from './Swagger';
import Footer from './Footer';

function App() {
  return (
    <Router>
      <div className="App">
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css"
          integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65"
          crossOrigin="anonymous"
        />

        <div className="content bg-white">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/swagger" element={<Swagger />} />
          </Routes>
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;
