import Ratio from 'react-bootstrap/Ratio';
import React from 'react';
import { Badge, Col, Row } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import BootstrapNavbar from './BootstrapNavbar';
import { lightGradient, ulStyle } from './utils';

function Swagger() {
  return (
    <div>
      <BootstrapNavbar isHome={false} />
      <Container>
        <br />
        <h2>
          <FontAwesomeIcon icon={faPlay} style={{ color: '6610F2' }} />
          &thinsp;
          Swagger
        </h2>
        <p className="fs-5">
          Test the below endpoints in your browser and
          retrieve the latest news articles as JSON;
          optionally download the response
        </p>
        <Row className="pt-5 pb-5" style={lightGradient()}>
          <Col xs={0} md={3} />
          <Col xs={12} md={6}>
            <ul style={ulStyle()}>

              <li className="fs-5">
                <Badge bg="secondary">1</Badge>
                &thinsp;
                Select an endoint to expand it and and press
                <strong>
                  &thinsp;Try it out
                </strong>
              </li>
              <li className="fs-5 mt-4">
                <Badge bg="secondary">2</Badge>
                &thinsp;
                Press
                <strong>
                  &thinsp;Execute&thinsp;
                </strong>
                to get the response in JSON format
              </li>
              <li className="fs-5 mt-4">
                <Badge bg="secondary">3</Badge>
                &thinsp;
                View the response and example CURL
              </li>
            </ul>
          </Col>
          <Col xs={0} md={3} />
        </Row>

      </Container>
      <div style={lightGradient()}>
        <Ratio aspectRatio="1x1">
          <embed src="/api/v1/" />
        </Ratio>
        <br />
        <br />
        <br className="d-md-none" />
        <br className="d-md-none" />
        <br className="d-md-none" />
        <br className="d-md-none" />
        <br className="d-md-none" />
        <br className="d-md-none" />
      </div>
    </div>
  );
}

export default Swagger;
