import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

function navlinks() {
  return (
    <>
      <Nav.Link href="#services">Services</Nav.Link>
      <Nav.Link href="#endpoints">Endpoints</Nav.Link>
      <Nav.Link href="#tryit">Try It</Nav.Link>
      <Nav.Link href="#feed">Feed</Nav.Link>
    </>
  );
}

function BootstrapNavbar(props) {
  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Container>
        <Nav.Link href="/">
          <img
            alt=""
            src="/apple-icon-57x57.png"
            width="32"
            height="32"
            className="d-inline-block align-top"
          />
        </Nav.Link>

        &thinsp;
        <Navbar.Brand className="me-auto" href="/">OkSurf!</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {props.isHome && navlinks()}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default BootstrapNavbar;
