import { faPlay, faRocket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NewsCards from './NewsCards';

function Feed() {
  return (
    <div id="feed" className="mb-5">
      <br />
      <h2>
        <FontAwesomeIcon icon={faPlay} style={{ color: '6610F2' }} />
        &thinsp;Feed
      </h2>
      <p className="fs-5">
        Use our API to power news-feeds in your applications.
      </p>
      <span>
        <FontAwesomeIcon icon={faRocket} className="text-primary" />
          &thinsp; Example news feed driven by the OkSurf API:
      </span>
      <NewsCards />
    </div>
  );
}

export default Feed;
