function Hero({ children, imgPath, imgHeight }) {
  const heroStyle = {
    backgroundImage: `url('${imgPath}')`,
    backgroundSize: 'cover',
    height: `${imgHeight}vh`,
  };

  return (
    <header style={{ paddingLeft: 0 }}>
      <div
        className="p-5 text-center bg-image"
        style={heroStyle}
      >
        {children}
      </div>
    </header>
  );
}

export default Hero;
