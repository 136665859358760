import React from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { Col, Row, Spinner } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lightGradient } from './utils';

class NewsCards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cards: [],
      cardIndex: 0,
      isPending: true,
      error: '',
    };
    this.url = `${window.location.protocol}//${window.location.host}/`;
    this.step = 4;
    this.newsLeft = this.newsLeft.bind(this);
    this.newsRight = this.newsRight.bind(this);
  }

  componentDidMount() {
    this.fetchNews();
  }

  fetchNews() {
    fetch(`${this.url}api/v1/news-feed`)
      .then((res) => {
        if (!res.ok) {
          throw Error('could not fetch data for that resource');
        }
        return res.json();
      })
      .then((data) => {
        const sections = [
          'US', 'World', 'Business', 'Technology', 'Entertainment', 'Sports', 'Science', 'Health',
        ];

        let minLen = 100000;
        sections.forEach((section) => {
          if (data[section].length < minLen) {
            minLen = data[section].length;
          }
        });

        const cards = [];
        for (let index = 0; index < minLen; index++) {
          sections.forEach((section) => {
            const article = data[section][index];
            article['section'] = section;
            cards.push(article);
          });
        }
        this.setState({
          cards,
          cardIndex: 0,
          isPending: false,
          error: '',
        });
      })
      .catch((err) => {
        this.setState({
          cards: [],
          cardIndex: 0,
          isPending: false,
          error: err.message,
        });
      });
  }

  newsLeft() {
    if (this.state.cardIndex > 0) {
      this.setState((previousState) => ({ cardIndex: previousState.cardIndex - 1 }));
    }
  }

  newsRight() {
    if (this.state.cardIndex < this.state.cards.length - this.step - 1) {
      this.setState((previousState) => ({ cardIndex: previousState.cardIndex + 1 }));
    }
  }

  build_row() {
    const cardStyle = {
      width: '100%',
      height: '200px',
      objectFit: 'cover',
    };
    const iconStyle = {
      width: '20px',
      height: '20px',
      objectFit: 'cover',
    };
    const colStyle = {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      height: '375px',
    };
    const sourceStyle = {
      textShadow: '#000033 1px 0 10px',
    };
    const cardBodyStyle = {
      position: 'relative',
      zIndex: '2',
    };
    const index = this.state.cardIndex;
    return this.state.cards.slice(index, index + this.step).map((article) => (
      <Col xs={12} md={6} lg={3} className="mask pt-2 pb-2 ps-2 pe-2" style={colStyle}>
        <Card className="h-100 shadow">
          <Card.Img style={cardStyle} variant="top" src={article.og.length > 1 ? article.og : '/features.svg'} />
          <Card.ImgOverlay className="text-light text-end">
            <Card.Img
              className="shadow-lg"
              style={iconStyle}
              src={article.source_icon.length > 1 ? article.source_icon : '/features.svg'}
            />
              &thinsp;
            <strong style={sourceStyle}>
              {article.source}
            </strong>
          </Card.ImgOverlay>
          <Card.Title className="pt-2 pb-2 ps-3 bg-info mb-0">{article.section}</Card.Title>
          <Card.Body className="bg-light" style={cardBodyStyle}>
            <Card.Text>
              <Card.Link href={article.link} className="text-dark" title={article.title}>
                {article.title.length < 70 ? article.title : `${article.title.slice(0, 70)}...`}
              </Card.Link>
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    ));
  }

  render() {
    if (this.state.error.length) {
      return (
        <div className="text-danger">
          {this.state.error}
        </div>
      );
    }
    if (this.state.isPending) {
      return (
        <div className="text-dark">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      );
    }

    return (
      <div>
        <br />
        <br />
        <span className="text=light">
          <Button onClick={this.newsLeft} variant="primary">
            <FontAwesomeIcon icon={faChevronLeft} className="text-light" />
          </Button>
          &emsp;
          <Button onClick={this.newsRight} variant="primary">
            <FontAwesomeIcon icon={faChevronRight} className="text-light" />
          </Button>
        </span>
        <Container>
          <Row className="mt-2 mb-4" style={lightGradient()}>
            {this.build_row()}
          </Row>
        </Container>
        <br />
      </div>
    );
  }
}

export default NewsCards;
