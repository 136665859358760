import Ratio from 'react-bootstrap/Ratio';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { lightGradient } from './utils';
import CodeBlocks from './CodeBlocks';

function TryIt() {
  return (
    <div id="tryit" className="mb-5 mt-5">
      <br />
      <h2>
        <FontAwesomeIcon icon={faPlay} style={{ color: '6610F2' }} />
        &thinsp;Try It
      </h2>
      <p className="fs-5 mb-4">
        Use the below Swagger documentation to try the API. Select
        an endpoint and and press&thinsp;
        <strong>
          Try it out
        </strong>
        . An API response and CURL command will be visible after pressing the&thinsp;
        <strong>
          Execute
        </strong>
        &thinsp;button.
      </p>
      <Ratio aspectRatio="16x9" style={lightGradient()}>
        <embed src="/api/v1/" />
      </Ratio>
      <br />
      <br />
      <p className="fs-5 mb-4">
        Use the OkSurf API with your favorite programming language.
        Here are some simple examples:
      </p>
      <CodeBlocks />
      <br />
    </div>
  );
}

export default TryIt;
