import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React from 'react';
import { lightGradient } from './utils';

function privacyModalContent() {
  const sections = [
    [
      'Does the OkSurf application collect information about you?',
      `OkSurf does not collect information about any users. In the
      event that OkSurf begins to require user accounts to access
      the API, basic details required for signup and API usage will
      be stored (email, username/credentials, and access token(s)).
      User data is never shared with third-parties. OkSurf uses
      Google Analytics to gain insight on traffic sources and general
      site metrics.`,
    ],
    [
      'Is my IP address recorded or tracked?',
      `We do not permanently store any user's IP address. We do however
      temporarily track IP addresses to mitigate service abuse and to
      implement access control and request-rate limiting.`,
    ],
    [
      'Are cookies used by OkSurf?',
      `OkSurf does not use cookies to store user data or to track users.
      In the event that OkSurf enforces user accounts for API access, OkSurf
      reserves the right to implement client side sessions to to authorize
      API access by all end-users.`,
    ],
    [
      'Third party links/vendors/ads',
      `OkSurf does not display advertisements, but reserves the right to
      do so in the future.`,
    ],
    [
      'Terms of Service',
      'Please read our Terms and Conditions.',
    ],
    [
      'Will this website privacy policy change in future?',
      `OkSurf reserves the right to update and change this policy as
      deemed necessary. The most recent version will be updated here.`,
    ],
  ];

  return sections.map(([title, text]) => (
    <>
      <strong>
        {title}
      </strong>
      <p className="mt-2 mb-3">
        {text}
      </p>
    </>
  ));
}

function PrivacyModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Privacy Policy
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={lightGradient()}>
        <p>
          {privacyModalContent()}
        </p>
        <p>
          Last Updated: 20MAR2023
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PrivacyModal;
