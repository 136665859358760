export function lightGradient() {
  return {
    backgroundImage: 'radial-gradient(circle, rgba(246,246,246,1) 0%, rgba(255,255,255,1) 100%)',
    backgroundSize: 'cover',
  };
}

export function ulStyle() {
  return { listStyleType: 'none', lineHeight: '2.0' };
}
