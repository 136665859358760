import { Col, Row } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import Hero from './Hero';

function HeroImg() {
  const rowStyle = {
    height: '75vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
  return (
    <Hero imgPath="/hero-bg.png" imgHeight={100}>
      <Row className="mt-lg-4" style={rowStyle}>
        <Col xs={12} sm={6}>
          <div className="mask" style={{ backgroundColor: 'rgba(0, 0, 0, 0.3)' }}>
            <div className="d-flex justify-content-center align-items-center h-100 pb-2 pt-3">
              <div className="text-white">
                <h1>OkSurf News API </h1>
                <h4 className="mb-3">Free for programmatic consumption</h4>
                <a
                  className="btn btn-outline-light btn-lg mb-3"
                  href="/swagger"
                  role="button"
                >
                  Swagger
                </a>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={12} sm={6}>
          <div>
            <Image src="/hero-img.png" fluid="true" height="500" width="490" />
          </div>
        </Col>
      </Row>
    </Hero>
  );
}

export default HeroImg;
